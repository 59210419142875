<template>
  <div ref="addsecond">
    <el-form
      :inline="true"
      label-width="auto"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="省份">
        <el-input
          size="small"
          clearable
          v-model="formInline.provinceName"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="城市">
        <el-input
          size="small"
          clearable
          v-model="formInline.cityName"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input
          size="small"
          clearable
          v-model="formInline.address"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="酒店ID">
        <el-input
          size="small"
          type="number"
          clearable
          v-model="formInline.hotelId"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="酒店名称">
        <el-input
          size="small"
          clearable
          v-model="formInline.ctripHotelName"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="酒店电话">
        <el-input
          size="small"
          clearable
          v-model="formInline.hotelTel"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="星级">
        <!-- <el-input
          size="small"
          clearable
          v-model="formInline.starRating"
          placeholder=""
        ></el-input> -->
        <el-select size="small" v-model="formInline.starRating">
          <el-option
            v-for="item in starRatingOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="经度">
        <el-input
          size="small"
          clearable
          v-model="formInline.lng"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="纬度">
        <el-input
          size="small"
          clearable
          v-model="formInline.lat"
          placeholder=""
        ></el-input>
      </el-form-item> -->
      <el-form-item label="标签">
        <el-select
          @change="tag"
          size="small"
          v-model="formInline.tag"
          placeholder="请选择"
        >
          <el-option
            v-for="item in option1"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收款账户">
        <el-input
          size="small"
          clearable
          v-model="formInline.Collection"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="收款人">
        <el-input
          size="small"
          clearable
          v-model="formInline.paymentName"
          placeholder="收款人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="支付类型">
        <el-select
          size="small"
          v-model="formInline.paymentType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in paymentTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户行" v-show="formInline.paymentType == 2">
        <el-select
          size="small"
          v-model="formInline.openingBankName"
          filterable
          allow-create
          placeholder="请选择"
        >
          <el-option
            v-for="item in openingBankNameOption"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="酒店联系人">
        <el-input
          size="small"
          clearable
          v-model="formInline.user"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input
          size="small"
          clearable
          v-model="formInline.style"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="运营助理">
        <el-select
          @change="tag"
          size="small"
          v-model="formInline.assistant"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in option4"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款方式">
        <el-select size="small" v-model="formInline.pay" placeholder="请选择">
          <el-option
            v-for="item in option3"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发单方式">
        <el-select size="small" v-model="formInline.bill" placeholder="请选择">
          <el-option
            v-for="item in option2"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formInline.bill == '邮箱'" :label="formInline.bill">
        <el-input
          size="small"
          clearable
          v-model="formInline.msg"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item v-if="formInline.bill == '小程序'" label="酒店相关人员">
        <!-- <el-input
          size="small"
          clearable
          v-model="formInline.wecat"
          placeholder="多个以 , 分割"
        ></el-input> -->
         <el-select size="small" multiple filterable v-model="hotelManagerIds" placeholder="请选择" @change="changeHotelManagerIds">
          <el-option
            v-for="item in hotelManagerList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      

      <el-form-item label="可开发票">
        <el-select
          size="small"
          v-model="formInline.isInvoice"
          placeholder="请选择"
        >
          <el-option
            v-for="item in isInvoiceoption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="width: 100%; display: flex; justify-content: center">
        <div style="">
          <el-button size="small" @click="canal">取消</el-button>
          <el-button size="small" @click="onSubmit" type="primary"
            >提交审核</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// 获取酒店完整信息接口
import {
  serachHotel_api,
  serachHoteltwo_api,
  serachTag_api,
  getBankList_api,
  gethotelManagerList_api
} from "../../../apis/hotel";
import local from "@/utils/local.js";
export default {
  props: {
    status: {
      type: [Object],
    },
  },
  data() {
    return {
      //新增酒店表单
      formInline: {
        provinceName: "",
        cityName: "",
        address: "",
        hotelId: "",
        ctripHotelName: "",
        starRating: "",
        tag: "",
        Collection: "",
        user: "",
        style: "",
        assistant: "",
        bill: "",
        msg: "",
        pay: "",
        hotelTel: "",
        lat: "",
        lng: "",
        wecat: "",
        isInvoice: "",
        paymentName: "",
        paymentType: "",
        openingBankName: "",
        hotelManagerIds:'',
      },
      hotelManagerIds:[],
      hotelManagerList:[],
      //酒店标签
      option1: [
        {
          value: "选项1",
          label: "一般酒店",
        },
        {
          value: "选项2",
          label: "核心酒店",
        },
      ],
      //发单方式
      option2: [
        {
          value: "0",
          label: "邮箱",
        },
        {
          value: "选项2",
          label: "产品经理",
        },
        {
          value: "选项3",
          label: "小程序",
        },
        {
          value: "选项4",
          label: "后台发单",
        },
      ],
      //付款方式
      option3: [
        {
          value: "选项1",
          label: "一单一结",
        },
        {
          value: "选项2",
          label: "预付款",
        },
      ],
      paymentTypeOption: [
        {
          value: "1",
          label: "支付宝",
        },
        {
          value: "2",
          label: "银行卡",
        },
      ],
      isInvoiceoption: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      starRatingOption:[
        {value:1,label:"一星"},
        {value:2,label:"二星"},
        {value:3,label:"三星"},
        {value:4,label:"四星"},
        {value:5,label:"五星"},
      ],
      openingBankNameOption: [],
      // 运营助理
      option4: [],
      hotelId: local.get("hotelId"),
      cityId: local.get("cityId"),
      nreArr: [],
    };
  },
  created() {
    this.gethotelManagerList()
    this.getBankList();
    this.getHotel();
    // console.log(local.get("hotelId"));
  },
  methods: {
    async getBankList() {
      let { code, data } = await getBankList_api();
      if (code == 0) {
        this.openingBankNameOption = data;
      }
    },
    //获取酒店相关人员列表
    async gethotelManagerList(){
       let {code,data}=await gethotelManagerList_api()
       if(code==0){
         this.hotelManagerList=data
       }
    },
    changeHotelManagerIds(){
       console.log(this.hotelManagerIds)
       this.formInline.hotelManagerIds=this.hotelManagerIds.join(',')
    },
    //取消提交
    canal() {
      this.$router.push("/hotel/hotelList");
    },
    //提交审核
    onSubmit() {
      this.start();
    },
    //获取酒店详细信息
    async getHotel() {
      let { code, data } = await serachHoteltwo_api({
        hotelId: this.hotelId,
        cityId:this.cityId
      });
      if (code == 0) {
        console.log(data, "data");
        this.formInline.provinceName = data.provinceName;
        this.formInline.cityName = data.cityName;
        this.formInline.hotelId = this.hotelId;
        this.formInline.ctripHotelName = data.ctripHotelName;
        this.formInline.starRating = data.starRating;
        this.formInline.address = data.address;
        this.formInline.hotelTel = data.hotelTel;
        this.formInline.lng = data.longitude;
        this.formInline.lat = data.latitude;
        this.formInline.wecat = data.wchatGroupName;
        this.formInline.Collection = data.Collection;
      }
    },
    // 获取运营助理
    async tag() {
      let { code, data } = await serachTag_api({
        typeCore: this.formInline.tag == "一般酒店" ? "0" : "1",
      });
      if (code == 0) {
        console.log(data, "8520");
        // this.formInline.assistant = data.userName;
        this.option4 = data;
        this.nreArr = data;
      }
    },
    //运营助理模糊搜索
    remoteMethod(query) {
      if (query !== "") {
        this.option4 = this.nreArr.filter((item) => {
          return item.userName.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      } else {
        this.option4 = [];
      }
    },

    //开始审核
    start() {
      console.log(this.wecat);
      let billType;
      if (this.formInline.bill == "邮箱") {
        billType = 0;
      } else if (this.formInline.bill == "产品经理") {
        billType = 1;
      } else if (this.formInline.bill == "小程序") {
        billType = 2;
      } else if (this.formInline.bill == "后台发单") {
        billType = 3;
      }

      if(this.formInline.hotelTel==local.get('telephone')){
        this.$message.warning('酒店电话不能是自己电话')
      }else{
        this.$axios({
        method: "post",
        url: "/product/hotel/addHotel",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          address: this.formInline.address,
          provinceName: this.formInline.provinceName,
          cityName: this.formInline.cityName,
          ctripHotelId: this.formInline.hotelId,
          ctripHotelName: this.formInline.ctripHotelName,
          starRating: this.formInline.starRating,
          typeCore: this.formInline.tag == "一般酒店" ? "0" : "1",
          paymentAccount: this.formInline.Collection,
          contacts: this.formInline.user,
          contactsTel: this.formInline.style,
          operateManagerId: this.formInline.assistant,
          billType: billType,
          email: this.formInline.msg,
          payType: this.formInline.pay == "一单一结" ? "0" : "1",
          longitude: this.formInline.lng,
          latitude: this.formInline.lat,
          wchatGroupName: this.formInline.wecat,
          isInvoice: this.formInline.isInvoice,
          hotelTel: this.formInline.hotelTel,
          paymentType: this.formInline.paymentType,
          paymentName: this.formInline.paymentName,
          openingBankName: this.formInline.openingBankName,
          hotelManagerIds: this.formInline.hotelManagerIds,
        },
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.status.second = false;
            this.status.end = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      }


     
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form {
  width: 80%;
  margin: 60px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .el-form-item {
    margin: 0 0 30px 0;
    .el-button {
      margin: 10px 15px;
    }
  }
}
::v-deep input::-webkit-outer-spin-button,

  ::v-deep input::-webkit-inner-spin-button {

    -webkit-appearance: none !important;

  }

  ::v-deep input[type="number"] {

    -moz-appearance: textfield !important;

  }
</style>