<template>
  <div ref="addfirst">
    <el-form
      :inline="true"
      label-width="auto"
      :model="formInline"
      :rules="rules"
      class="demo-form-inline"
    >
      <!-- <el-form-item label="省份">
        <el-input
          size="small"
          clearable
          v-model="formInline.provinceName"
          placeholder=""
        ></el-input>
      </el-form-item> -->
      <el-form-item label="城市">
        <!-- <el-input
          size="small"
          clearable
          v-model="formInline.cityName"
          placeholder=""
        ></el-input> -->
        <el-select
          size="small"
          filterable
          v-model="formInline.cityId"
          placeholder="请选择城市"
        >
          <el-option
            v-for="item in allCityList"
            :key="item.id"
            :label="item.cityName"
            :value="item.cityId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="酒店ID">
        <el-input
          size="small"
          type="number"
          clearable
          v-model="formInline.hotelId"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="choose">筛选</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
//筛选酒店接口
import { choose_api, getAllCity_api } from "../../../apis/hotel";
import local from "@/utils/local.js";
export default {
  props: {
    status: {
      type: [Object],
    },
  },
  data() {
    return {
      //新增酒店表单
      formInline: {
        provinceName: "",
        cityName: "",
        hotelId: this.$route.params.id ? this.$route.params.id : "",
        isSuper: this.$route.params.isSuper ? this.$route.params.isSuper : "",
        cityId: "",
      },
      rules: {
        hotelId: { type: "number", message: "必须为数字值", trigger: "blur" },
      },
      index: 1,
      allCityList: [],
    };
  },
  created() {
    console.log(this.$route.params.id, "6666");
    this.getAllCity();
  },
  methods: {
    async choose() {
      if (this.formInline.cityId) {
        let { code, data, msg } = await choose_api({
          provinceName: this.formInline.provinceName,
          cityName: this.formInline.cityName,
          hotelId: this.formInline.hotelId,
          isSuper: this.formInline.isSuper,
        });
        if (code == 0) {
          this.$confirm(
            data,
            "提示 !",
            {
              confirmButtonText: "下一步",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(async () => {
              this.status.first = false;
              this.status.second = true;
              //将酒店id存入本地
              local.set("hotelId", this.formInline.hotelId);
              local.set("cityId", this.formInline.cityId);
            })
            .catch(() => {});
        } else {
          this.$confirm(msg, "提示 !", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {})
            .catch(() => {});
        }
      }else{
        this.$message.warning('请选择城市')
      }
    },

    async getAllCity() {
      let { data, code } = await getAllCity_api();
      if (code == 0) {
        this.allCityList = data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form {
  margin-top: 60px;
  .el-form-item {
    margin: 0 0 0 10px;
  }
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>