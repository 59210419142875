<template>
  <!-- 新增酒店 -->
  <div class="addHotel">
    <img src="../../assets/imgs/流程.png" alt="加载失败" />
    <Addfirst ref="addfirst" :status="status" v-if="status.first"></Addfirst>
    <Addsecond
      ref="addsecond"
      :status="status"
      v-if="status.second"
    ></Addsecond>
    <Addfinish :status="status" v-if="status.end" ref="addfinish"></Addfinish>
  </div>
</template>

<script>
import Addfirst from "../../components/dialog/hotel/Addfirst.vue";
import Addsecond from "../../components/dialog/hotel/Addsecond.vue";
import Addfinish from "../../components/dialog/hotel/Addfinish.vue";
export default {
  components: { Addfirst, Addsecond, Addfinish },
  data() {
    return {
      status: {
        first: true,
        second: false,
        end: false,
      },
    };
  },
};
</script>

<style lang="less" scoped>
.addHotel {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 80px;
  }
}
</style>