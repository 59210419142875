<template>
  <div class="addfinish">
    <img src="../../../assets/imgs/成功.png" alt="加载失败" />
    <div class="title">酒店审核已提交成功！</div>
    <el-button size="mini" @click="goback" type="primary">返回</el-button>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Object,
    },
  },
  methods: {
    goback() {
      this.$router.push("/hotel/hotelList");
    },
  },
};
</script>

<style lang="less" scoped>
.addfinish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 50px 0 20px 0;
  }
  .title {
    color: #256ef1;
    font-size: 18px;
  }
  .el-button {
    margin: 50px 0 20px 0;
  }
}
</style>